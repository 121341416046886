// stores/trendingVideos.js
import { defineStore } from 'pinia'

export const useVideosStore = defineStore('trendingVideos', {
    state: () => ({
        trendingVideos: [],
    }),
    actions: {
        setTrendingVideos(videos) {
            this.trendingVideos = videos
        },
        addVideos(newVideos) {
            const existingIds = new Set(this.trendingVideos.map(video => video.id))
            const filteredVideos = newVideos.filter(video => !existingIds.has(video.id))
            this.trendingVideos.push(...filteredVideos)
        },
        saveState() {
            const stateToPersist = JSON.stringify(this.trendingVideos)
            window.sessionStorage.setItem('trendingVideos', stateToPersist)
        },
        restoreState() {
            const persistedState = window.sessionStorage.getItem('trendingVideos')
            if (persistedState) {
                this.trendingVideos = JSON.parse(persistedState)
            }
        },
    },
})
